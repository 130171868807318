import { useEffect, useState, createContext, useContext } from 'react'
import {Tab, TabList, TabPanel, TabPanels, Tabs} from '@chakra-ui/react'
import {useSearchParams} from 'react-router-dom'
import CategoryRows from '../../CategoryRows/CategoryRows'
import RowsCountedOld from '../RowsCountedOld/RowsCountedOld'
import {RowsDouble} from '../RowsDouble/RowsDouble'
import RowsMissed from '../RowsMissed/RowsMissed'
import {ItemsInvalid} from '../Items/ItemsInvalid/ItemsInvalid'
import {ItemsEdited} from '../Items/ItemsEdited/ItemsEdited'
import {RowsEmpty} from '../RowsEmpty/RowsEmpty'
import {ItemsCounted} from '../Items/ItemsCounted/ItemsCounted'
import {RowsCounted} from '../RowsCounted/RowsCounted'
import { CategoryFree } from '../CategoryFree'
import { PlanActual } from '../PlanActual'
import { InventoryResults } from '../InventoryResults'
import { InventoryFeedback } from '../InventoryFeedback'
import { CountingWorkingHours } from '../CountingWorkingHours'
import { DeviceCount } from '../DeviceCount'
import { SupplyGap } from '../SupplyGap'

export const CountingTabsContext = createContext()

export const CountingTabs = ({countingId, refreshCounting, counting, updateCounting, setCounting}) => {
    const [searchParams, setSearchParams] = useSearchParams()

    const [revisedTabs, setRevisedTabs] = useState([])

    const toggleRevisedTab = (tab) => {
        revisedTabs.includes(tab) ? setRevisedTabs(revisedTabs.filter(t => t !== tab)) : setRevisedTabs([...revisedTabs, tab])
    }

    const tabsData = [
        {
            name: 'Counted Items',
            path: 'counted-items',
            component: <ItemsCounted countingId={countingId} refreshCounting={refreshCounting} counting={counting} />,
        },
        {
            name: 'Categories',
            path: 'categories',
            component: <CategoryRows countingId={countingId}/>,
        },
        {
            name: 'Counted Rows',
            path: 'counted-rows',
            component: <RowsCounted countingId={countingId} refreshCounting={refreshCounting} counting={counting}/>,
        },
        {
            name: 'Category Free Rows',
            path: 'category-free',
            component: <CategoryFree countingId={countingId}/>,
        },
        {
            name: 'Plan/Actual',
            path: 'plan-actual',
            component: <PlanActual refreshCounting={refreshCounting} countingId={countingId}/>
        },
        {
            name: 'Supply Gap',
            path: 'supply-gap',
            component: <SupplyGap refreshCounting={refreshCounting} countingId={countingId} supplyGap={true}/>
        },
        // {
        //     name: 'Counted Rows Old',
        //     path: 'counted-rows-old',
        //     component: <RowsCountedOld countingId={countingId}/>,
        // },

        {
            name: 'Rows counted 0',
            path: 'empty-rows',
            component: <RowsEmpty countingId={countingId}/>,
        },
        {
            name: 'Double Rows',
            path: 'double-rows',
            component: <RowsDouble  refreshCounting={refreshCounting} countingId={countingId}/>,
        },
        {
            name: 'Missed Rows',
            path: 'missed-rows',
            component: <RowsMissed countingId={countingId}/>,
        },
        {
            name: 'Invalid Items',
            path: 'invalid-items',
            component: <ItemsInvalid refreshCounting={refreshCounting} countingId={countingId}/>
        },
        {
            name: 'Edited Items',
            path: 'edited-items',
            component: <ItemsEdited refreshCounting={refreshCounting} countingId={countingId}/>
        }, 
        {
            name: 'Counting Hours',
            path: 'counting-hours',
            component: <CountingWorkingHours refreshCounting={refreshCounting} />
        }, 
        {
            name: 'Device Count',
            path: 'device-count',
            component: <DeviceCount />
        },
        {
            name: 'Inventory Results',
            path: 'inventory-results',
            component: <InventoryResults />
        },
        {
            name: 'Feedback',
            path: 'inventory-feedback',
            component: <InventoryFeedback countingId={countingId} counting={counting} refreshCounting={refreshCounting}/>
        }

        
       
    ]
    const handleTabsChange = (index) => {
        setSearchParams({tab: tabsData[index].path})
    }

    const findTabIndex = () => {
        const tabParam = searchParams.get('tab')
        return tabsData.findIndex(tab => tab.path === tabParam)
    }

    useEffect(() => {
        if (findTabIndex() === -1) handleTabsChange(0) // default tab
    }, []) // run once on mount

    return (
        <CountingTabsContext.Provider value={{revisedTabs, toggleRevisedTab, counting, refreshCounting, updateCounting, setCounting}}>
            <Tabs
                isLazy
                variant='soft-rounded'
                colorScheme='green'
                index={findTabIndex()}
                onChange={handleTabsChange}
            >
                <TabList position='sticky' top={-4} bg='white' zIndex='sticky' >
                    {tabsData.map((tab, index) => (
                        <Tab key={index}>{tab.name}</Tab>
                    ))}
                </TabList>
                <TabPanels >
                    {tabsData.map((tab, index) => (
                        <TabPanel key={index}>{tab.component}</TabPanel>
                    ))}
                </TabPanels>
            </Tabs>
        </CountingTabsContext.Provider>
    )
}
